<template>
  <div class="_bg-default _80w _100vh d-flex align-center justify-center">
    <v-card width="50%" height="50vh" class=" rounded-lg">
      <div class="pa-3">
        <h3>Import Psychologist</h3>
        <v-file-input
          accept=".xls, .xlsx"
          v-model="file"
          label="Klik untuk memilih file"
        ></v-file-input>
        <v-btn color="purple" @click="importList" dark depressed>Import</v-btn>
      </div>
      <div class="py-3">
        <v-divider></v-divider>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
      </div>
      <div class="pa-3">
        <p class="mb-1">Download templat file :</p>
        <v-btn
          color="green"
          href="https://pampsi.gamifindo.com/template/dataUser.xlsx"
          dark
          depressed
          ><v-icon>mdi-microsoft-excel</v-icon> Template</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "importPsycholog",
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    importList() {
      this.loading = true;
      let data = new FormData();
      data.append("file", this.file);
      this.$store
        .dispatch("admin/importUser", data)
        .then(() => {
          this.loading = false;
          this.$router.push("/admin/list-psikolog/all/list");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
